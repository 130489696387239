import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import "./App.css";
import cap from "./assets/homelogo.png";

function Bot() {
  const [textInput, setTextInput] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [messages, setMessages] = useState([]);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [formattedText, setFormattedText] = useState("");
  const chatContainerRef = useRef();
  const [botLoading, setBotLoading] = useState(false);

  useEffect(() => {
    let sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      sessionId = Math.random().toString(36).substring(7);
      sessionStorage.setItem("session_id", sessionId);
    }
  }, []);

  useEffect(() => {
    setSendDisabled(textInput.trim() === "" && !fileInput);
  }, [textInput, fileInput]);

  useEffect(() => {
    if (messages.length > 0) {
      setTextInput("");
    }
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom();
    }
  }, [messages]);

  useEffect(() => {
    if (formattedText) {
      const newMessages = [...messages];
      newMessages.push({
        sender: "bot",
        message: formattedText,
        copied: false,
      });
      setMessages(newMessages);
      setFormattedText("");
      setBotLoading(false);
    }
  }, [formattedText, messages]);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      const sessionId = sessionStorage.getItem("session_id");
      if (!textInput.trim() && !fileInput) {
        console.log("Please enter a message or upload a file.");
        return;
      }
      setBotLoading(true);
      const newMessages = [...messages];
      if (textInput.trim()) {
        newMessages.push({ sender: "You", message: textInput, copied: false });
      }
      setMessages(newMessages);

      const formData = new FormData();
      formData.append("text", textInput);
      formData.append("session_id", sessionId);

      const response = await fetch(`https://py.neuronestai.in/Neurobot`, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (data.text) {
        setFormattedText(data.text);
      }

      setMessages(newMessages);
      setTextInput("");
      setFileInput(null);
      scrollToBottom();
    } catch (error) {
      console.error("Error:", error);
      setBotLoading(false);
    }
  };

  const isMobileDevice = () => {
    return (
      typeof window.orientation !== "undefined" ||
      navigator.userAgent.indexOf("IEMobile") !== -1
    );
  };

  const handleKeyPress = (e) => {
    if (!isMobileDevice() && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const handleClickCard = (message) => {
    setTextInput(message);
    setTimeout(() => {
      document.querySelector(".message-button").click();
    }, 100);
  };

  return (
    <div className="chefbot ">
      <div className="chat-container" ref={chatContainerRef}>
        <div className="message-containerdiv">
          {messages.length === 0 && (
            <div className="center-content">
              <img src={cap} alt="Logo" className="center-logo" />
              <div className="row mt-5">
                <div className="col-lg-6 col-6">
                  <div
                    className="card  default-textcard "
                    onClick={() =>
                      handleClickCard("Want to know more about Neuronest AI?") } >
                    <div className="card-body p-3">
                      <h6 className="card-text default-prompt">
                        Want to know more about Neuronest AI?
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-6">
                  <div
                    className="card default-textcard  "
                    onClick={() =>
                      handleClickCard("Need AI-based suggestions for my business problem.")}                >
                    <div className="card-body p-3">
                      <h6 className=" card-text default-prompt">
                      Need AI-based suggestions for my business problem.
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="messages-container">
            {messages.map((message, index) => {
              if (message.sender === "bot") {
                return (
                  <div key={index} className={`message ${message.sender} bot`}>
                    <div>
                      <img src={cap} alt="cap" className="cap" />
                      <span className="headtextwork pt-3">NeuronestBot</span>
                      <br />
                      <div className="message-content mt-1">
                        <pre className=" textfonts mb-1 mt-1">
                          {message.message}
                        </pre>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={index} className={`message ${message.sender} You`}>
                    <div className="message-content">
                      {typeof message.message === "string" ? (
                        <p className="jv">{message.message}</p>
                      ) : (
                        <p className="jv">{message.message}</p>
                      )}
                    </div>
                  </div>
                );
              }
            })}
            {botLoading && (
              <div className="message bot">
                <div className="message-content">
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    className="icon-sent2"
                    beatFade
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="d-flex justify-content-center">
          <form onSubmit={sendMessage} className="message-form">
            <img src={cap} alt="cap" className="cap" />
            <textarea
              className=""
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  handleKeyPress(e);
                }
              }}
              placeholder="Explore Neuronest AI with me!..."
            ></textarea>
            <button
              type="submit"
              className="message-button"
              disabled={sendDisabled}
            >
              <svg
                width="36"
                height="36"
                className="icon-sent1"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5013 17.5001L13.1705 22.0472C14.0864 23.8456 16.7467 23.7336 17.5014 21.8649L23.1617 7.84873C23.954 5.88679 21.8768 3.99164 19.9313 4.90138L6.0176 11.4075C4.16684 12.2729 4.23463 14.8726 6.12808 15.6446L10.5013 17.5001ZM10.5013 17.5001L14.5846 13.4167"
                  stroke="white"
                  strokeWidth="2.76758"
                />
              </svg>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Bot;
