import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Bot from './bot';


function App() {
  return (
  <div>
    <Bot/>
  </div>
  
  );
}

export default App;
